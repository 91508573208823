import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import PostsReducer from './reducers/PostsReducer';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import todoReducers from './reducers/Reducers';
import userLoginSlicer from './AuthSlicer'
import isLogedIn from './isLogedIn';
import AddUserSlicer from './AddUserSlicer';
import getAllCourses from './GetAllCoursesSlicer';
import SingleCourseSlicer from './SingleCourseSlicer';
import DeleteCourseSlicer from './DeleteCourseSlicer';
import AddCourseSlicer from './AddCourseSlicer';
import addessonSlicer from './AddessonSlicer';
import getAllLessonsSlicer from './GetAllLessonSlicer';
import DeleteLessonSlicer from './DeleteLessonSlicer';
import GetUsersSlicer from './GetUsersSlicer';
import DeleteStudentSlicer from './DeleteStudentSlicer';
import GetSingleUSerSlicer from './GetSingleUSerSlicer';
import UpdateUserDataSlicer from './UpdateUserDataSlicer';
import GetFormConactSlicer from './GetFormConactSlicer';
import DeleteFromContactSlicer from './DeleteFromContactSlicer';
import GetFormJoinSlicer from './GetFormJoinSlicer';
import DeleteFromJointSlicer from './DeleteFromJointSlicer';
import StatisticsSlicer from './StatisticsSlicer';
import UpdateCourseDataSlicer from './UpdateCourseDataSlicer';
import GetSingleLessonDataSlicer from './GetSingleLessonDataSlicer';
import getAllAdminsSlicer from './Slices/users/getAllAdminsSlicer';
import deleteUserByIdSlicer from './Slices/users/deleteUserByIdSlicer';
import updateUsersSlicer from './Slices/users/updateUsersSlicer';
import DeleteLessonFileSlicer from './Slices/lessons/DeleteLessonFileSlicer';
//import { reducer as reduxFormReducer } from 'redux-form';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    newAuth : userLoginSlicer,
    isLoged : isLogedIn,
    addUsers : AddUserSlicer,

    addCourse : AddCourseSlicer,
    getAllCourses : getAllCourses,
    singleCourseData : SingleCourseSlicer,
    deleteCourse : DeleteCourseSlicer,
    updateCourse : UpdateCourseDataSlicer,

    addLessons : addessonSlicer,
    getLessons : getAllLessonsSlicer,
    getSingleLesson:GetSingleLessonDataSlicer,
    deleteLesson : DeleteLessonSlicer,
    DeleteLessonFileSlicer : DeleteLessonFileSlicer,

    getUser : GetUsersSlicer,
    getAllAdminsSlicer : getAllAdminsSlicer,
    getSingleUser : GetSingleUSerSlicer,
    deleteUser : DeleteStudentSlicer,
    updateUser : UpdateUserDataSlicer,
    updateUsersSlicer : updateUsersSlicer,
    deleteUserByIdSlicer : deleteUserByIdSlicer,

    formContact : GetFormConactSlicer,
    DeleteformContact : DeleteFromContactSlicer,

    formJoin : GetFormJoinSlicer,
    DeleteformJoin : DeleteFromJointSlicer,

    statistics : StatisticsSlicer,

    
    
    posts: PostsReducer,
    auth: AuthReducer,
		todoReducers,
	//form: reduxFormReducer,	
	
});

//const store = createStore(rootReducers);

export const store = createStore(reducers,  composeEnhancers(middleware));
