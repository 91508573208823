import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";
import LogoutFunction from "../services/LogoutFunction";

export const addUserFunction = createAsyncThunk("addUser", async (data) => {
  const tokenData = await JSON.parse(localStorage.getItem("userData")).token;

  const createUserData = new FormData();
  if (data.image !== "") {
    createUserData.append("image", data.image);
  }
  
  createUserData.append("title", data.title);
  createUserData.append("name", data.name);
  createUserData.append("mobile", data.mobile);
  createUserData.append("email", data.email);
  createUserData.append("password", data.password);
  createUserData.append("role", data.role);

  const request = await fetch(
    `${process.env.REACT_APP_VERSEL_API}/api/users/create`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${tokenData}`,
      },
      body: createUserData,
    }
  )
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error;
    });

  const response = request;
  return response;
});

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}

const AddUserSlicer = createSlice({
  name: "createUser",
  initialState: {
    loading: false,
    dataResponse: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(addUserFunction.pending, (state) => {
      state.loading = true;
      state.dataResponse = null;
      state.error = null;
    });
    builder.addCase(addUserFunction.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;
        formatError(state.error);
        LogoutFunction();
      } else if (action.payload.message === " User created Successfully") {
        state.dataResponse = action.payload;
        state.error = null;
      } else {
        state.loading = false;
        state.dataResponse = null;
        state.error = action.payload.message;
      }
    });
    builder.addCase(addUserFunction.rejected, (state, action) => {
      state.loading = false;
      state.dataResponse = null;
      state.error = action.payload.message;
    });
  },
});

export default AddUserSlicer.reducer;
