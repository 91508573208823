import { createAsyncThunk, createSlice, isFulfilled } from "@reduxjs/toolkit";
import swal from "sweetalert";
import LogoutFunction from "../services/LogoutFunction";


export const getAll = createAsyncThunk("getAllUsers", async (users) => {
  const tokenData = await JSON.parse(localStorage.getItem("userData")).token;

  const requset = await fetch(
    `${process.env.REACT_APP_VERSEL_API}/api/users/${users}`
  ,{
    method:"GET",
    headers:{
        "Authorization": `Bearer ${tokenData}`
    }
  })
    .then((respons) => respons.json())
    .then((result) => {
      console.log(result);
      return result;
    })
    .catch((error) => {
      console.log(error);
      formatError(error)
      return error;
    });

    return requset;
});

function formatError(errorResponse) {
    swal("Oops", `${errorResponse}`, "error");
  }

const getUsersSlicer = createSlice({
  name: "users/getAll",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },

  extraReducers: (builder) => {

    builder.addCase(getAll.pending,state=>{
        state.loading = true;
    })
    builder.addCase(getAll.fulfilled,(state,action)=>{
        state.loading = false;
        if (action.payload.message === "jwt expired") {
          state.error = action.payload.message;
          formatError(state.error);
          LogoutFunction();
        }
        else if(action.payload.message === "fetched successfully"){
            state.data = action.payload.data;
            state.error = null;
        }else{
            state.data = null;
            state.error = action.payload.message;
            formatError(action.payload.message);
        }
    })
    builder.addCase(getAll.rejected,(state,action)=>{
        state.loading = false;
        state.data = null ;
        state.error = action.payload.message;
        formatError(action.payload.message);
    })

  },
});

export default getUsersSlicer.reducer;


