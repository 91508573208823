import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";

import { Formik } from "formik";
import * as Yup from "yup";

import { getAllAdmins } from "../../../../store/Slices/users/getAllAdminsSlicer";
import { deleteUser } from "../../../../store/Slices/users/deleteUserByIdSlicer";
import { updateUserApi } from "../../../../store/Slices/users/updateUsersSlicer";

const loginSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Your name must consist of at least 3 characters ")
    .max(100, "Your name must consist of at most 100 characters ")
    .required("Please enter a name"),
  title: Yup.string()
    .min(3, "Your title must consist of at least 3 characters ")
    .max(200, "Your title must consist of at most 200 characters ")
    .required("Please enter a title"),
  email: Yup.string()
    .min(3, "Your email is to short ")
    .email()
    .required("Please enter a email"),
  mobile: Yup.number()
    .moreThan(11, "phone number must be 11 degite")
    .required("Please provide a mobile number"),
  role: Yup.string().required("Please select role"),
  // password: Yup.string()
  //   .min(8, "Your password must be at least 8 characters")
  //   .max(200, "Your password must be at most 200 characters"),
});

const DisplayAllUserTableData = () => {
  const { loading, error } = useSelector((s) => s.getAllAdminsSlicer);
  const { loading: loadongUpdate, error: errorUpdate } = useSelector(
    (s) => s.updateUsersSlicer
  );
  const { responst, error: errorDelete } = useSelector(
    (s) => s.deleteUserByIdSlicer
  );
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [formIntilaData, setFormIntilaData] = useState({
    name: "",
    title: "",
    email: "",
    mobile: "",
    role: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [addCard, setAddCard] = useState(false);

  const [singleUser, setSingleUser] = useState({});

  // Handle Form Submite
  const handleFormSubmite = (value, { resetForm }) => {
    const userData = {
      _id: singleUser._id,
      name: value.name,
      title: value.title,
      email: value.email,
      mobile: value.mobile,
      role: value.role,
    }
    
    dispatch(updateUserApi(userData)).then((res) => {
      if (res.payload.message === " User updated Successfully") {
        swal("User Updated successfully!", {
          icon: "success",
        });
        resetForm();
        setAddCard(false);
        getAllUsers();
      } else {
        swal("Error!", errorUpdate, "error");
        setAddCard(false);
      }
    });
  };
  // Handle Get All User Data
  const getAllUsers = () => {
    dispatch(getAllAdmins("admin")).then((resulet) => {
      if (resulet.payload.message === "fetched successfully") {
        console.log(resulet.payload.data);
        setUsers(resulet.payload.data);
      } else {
        swal("Error!", error, "error");
      }
    });
  };
  // Handle Update Data
  const handleUpdate = (content) => {
    setAddCard(true);
     setSingleUser(content);
    setFormIntilaData({
      name: content.name,
      title: content.title,
      email: content.email,
      mobile: content.mobile,
      role: content.role,
      password: "",
    });
  };
  // Handle Delete data
  const handleDeleteClick = (contentId) => {
    console.log(contentId);
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover user data again",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteUser(contentId)).then((res) => {
          if (res.payload.message === "deleted successfully") {
            swal(res.payload.message, {
              icon: "success",
            });
          } else {
            swal(errorDelete, {
              icon: "error",
            });
          }
          getAllUsers();
        });
      } else {
        swal("The user data is safe!");
      }
    });
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div className="col-12 d-flex justify-content-center gap-3">
            <Spinner animation="grow" variant="primary" />
            <Spinner animation="grow" variant="primary" />
            <Spinner animation="grow" variant="primary" />
          </div>
        </>
      ) : (
        <>
          {error ? (
            <>
              <div className="col-12 d-flex justify-content-center gap-3">
                <h2>{error}</h2>
              </div>
            </>
          ) : (
            <>
              {users && (
                <>
                  {users.length > 0 ? (
                    <>
                      <div className="col-12">
                        <Modal
                          className="modal fade"
                          show={addCard}
                          onHide={setAddCard}
                          size="lg"
                        >
                          <div className="" role="document">
                            <div className="">
                              <div className="modal-header">
                                <h4 className="modal-title fs-20">
                                  Update User Data
                                </h4>
                                <button
                                  type="button"
                                  className="btn-close"
                                  onClick={() => {
                                    setAddCard(false);
                                  }}
                                  data-dismiss="modal"
                                >
                                  <span></span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <Formik
                                  initialValues={{
                                    name: formIntilaData.name,
                                    title: formIntilaData.title,
                                    email: formIntilaData.email,
                                    mobile: formIntilaData.mobile,
                                    role: formIntilaData.role,
                                    password: formIntilaData.password,
                                  }}
                                  validationSchema={loginSchema}
                                  onSubmit={handleFormSubmite}
                                >
                                  {({
                                    values,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    handleReset,
                                  }) => (
                                    <form onSubmit={handleSubmit}>
                                      <div className="row">
                                        {/* Name  */}
                                        <div
                                          className={`col-12 form-group mb-3 ${
                                            values.name
                                              ? errors.name
                                                ? "is-invalid"
                                                : "is-valid"
                                              : ""
                                          }`}
                                        >
                                          <label className="text-label">
                                            name
                                          </label>
                                          <div className="input-group">
                                            <span className="input-group-text">
                                              <i className="fa fa-user" />
                                            </span>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter a name.."
                                              name="name"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.name}
                                            />
                                            <div
                                              id="val-name-error"
                                              className="invalid-feedback animated fadeInUp"
                                              style={{ display: "block" }}
                                            >
                                              {errors.name && errors.name}
                                            </div>
                                          </div>
                                        </div>
                                        {/* Title  */}
                                        <div
                                          className={`col-12 form-group mb-3 ${
                                            values.title
                                              ? errors.title
                                                ? "is-invalid"
                                                : "is-valid"
                                              : ""
                                          }`}
                                        >
                                          <label className="text-label">
                                            title
                                          </label>
                                          <div className="input-group">
                                            <span className="input-group-text">
                                              <i class="fa-brands fa-cuttlefish"></i>
                                            </span>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter a Title.."
                                              name="title"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.title}
                                            />
                                            <div
                                              id="val-title-error"
                                              className="invalid-feedback animated fadeInUp"
                                              style={{ display: "block" }}
                                            >
                                              {errors.title && errors.title}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        {/* Email  */}
                                        <div
                                          className={`col-12 form-group mb-3 ${
                                            values.email
                                              ? errors.email
                                                ? "is-invalid"
                                                : "is-valid"
                                              : ""
                                          }`}
                                        >
                                          <label className="text-label">
                                            email
                                          </label>
                                          <div className="input-group">
                                            <span className="input-group-text">
                                              <i class="fa-solid fa-envelope"></i>
                                            </span>
                                            <input
                                              type="email"
                                              className="form-control"
                                              placeholder="Enter a email.."
                                              name="email"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.email}
                                            />
                                            <div
                                              id="val-email-error"
                                              className="invalid-feedback animated fadeInUp"
                                              style={{ display: "block" }}
                                            >
                                              {errors.email && errors.email}
                                            </div>
                                          </div>
                                        </div>
                                        {/* Password  */}
                                        {/* <div
                                          className={`col-12 form-group mb-3 ${
                                            values.password
                                              ? errors.password
                                                ? "is-invalid"
                                                : "is-valid"
                                              : ""
                                          }`}
                                        >
                                          <label className="text-label">
                                            Password *
                                          </label>
                                          <div className="input-group transparent-append mb-2">
                                            <span className="input-group-text">
                                              {" "}
                                              <i className="fa fa-lock" />{" "}
                                            </span>

                                            <input
                                              type={`${
                                                showPassword
                                                  ? "text"
                                                  : "password"
                                              }`}
                                              className="form-control"
                                              id="val-password1"
                                              name="password"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.password}
                                              placeholder="Choose a safe one.."
                                            />

                                            <div
                                              className="input-group-text "
                                              onClick={() =>
                                                setShowPassword(!showPassword)
                                              }
                                            >
                                              {" "}
                                              {showPassword === false ? (
                                                <i className="fa fa-eye-slash" />
                                              ) : (
                                                <i className="fa fa-eye" />
                                              )}
                                            </div>
                                            <div
                                              id="val-username1-error"
                                              className="invalid-feedback animated fadeInUp"
                                              style={{ display: "block" }}
                                            >
                                              {errors.password &&
                                                errors.password}
                                            </div>
                                          </div>
                                        </div> */}
                                      </div>
                                      <div className="row">
                                        {/* Mobile  */}
                                        <div
                                          className={`col-12 form-group mb-3 ${
                                            values.mobile
                                              ? errors.mobile
                                                ? "is-invalid"
                                                : "is-valid"
                                              : ""
                                          }`}
                                        >
                                          <label className="text-label">
                                            mobile
                                          </label>
                                          <div className="input-group">
                                            <span className="input-group-text">
                                              <i class="fa-solid fa-mobile"></i>
                                            </span>
                                            <input
                                              type="tel"
                                              maxLength={11}
                                              className="form-control"
                                              placeholder="Enter a mobile.."
                                              name="mobile"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.mobile}
                                            />
                                            <div
                                              id="val-mobile-error"
                                              className="invalid-feedback animated fadeInUp"
                                              style={{ display: "block" }}
                                            >
                                              {errors.mobile && errors.mobile}
                                            </div>
                                          </div>
                                        </div>
                                        {/* Role  */}
                                        <div
                                          className={`col-12 form-group mb-3 ${
                                            values.role
                                              ? errors.role
                                                ? "is-invalid"
                                                : "is-valid"
                                              : ""
                                          }`}
                                        >
                                          <label className="text-label">
                                            role
                                          </label>
                                          <div className="input-group transparent-append mb-2">
                                            <select
                                              className="form-control"
                                              name="role"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.role}
                                              placeholder="Chose User Role"
                                            >
                                              <option defaultValue={null}>
                                                Please select
                                              </option>
                                              <option value="admin">
                                                admin
                                              </option>
                                              <option value="tescher">
                                                tescher
                                              </option>
                                              <option value="student">
                                                student
                                              </option>
                                            </select>
                                            <div
                                              id="val-role-error"
                                              className="invalid-feedback animated fadeInUp"
                                              style={{ display: "block" }}
                                            >
                                              {errors.role && errors.role}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                     {loadongUpdate ? (
                                        <>
                                          <button
                                            className="btn me-2 btn-primary"
                                            disabled
                                          >
                                              <Spinner animation="grow" variant="primary" />
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            type="submit"
                                            className="btn me-2 btn-primary"
                                          >
                                            Update
                                          </button>
                                        </>
                                      )} 
                                    </form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </div>
                        </Modal>
                        <div className="card">
                          <div className="card-body">
                            <div className="w-100 table-responsive">
                              <div
                                id="example_wrapper"
                                className="dataTables_wrapper"
                              >
                                <table
                                  id="example"
                                  className="display w-100 dataTable"
                                >
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>title</th>
                                      <th>Email</th>
                                      <th>Mobile</th>
                                      <th>Role</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {users.map((content, index) => (
                                      <tr key={index}>
                                        <>
                                          <td>{content.name}</td>
                                          <td>{content.title}</td>

                                          <td>
                                            <Link to={"#"}>
                                              {content.email}
                                            </Link>
                                          </td>
                                          <td>{content.mobile}</td>
                                          <td>
                                            <span
                                              variant="success light"
                                              class="badge bg-success"
                                            >
                                              {content.role}
                                            </span>
                                          </td>

                                          <td>
                                            <div className="d-flex">
                                              <a
                                                className="btn btn-secondary shadow btn-xs sharp me-2"
                                                onClick={() =>
                                                  handleUpdate(content)
                                                }
                                              >
                                                <i class="fa-solid fa-pen"></i>
                                              </a>
                                              <a
                                                className="btn btn-danger shadow btn-xs sharp"
                                                onClick={() =>
                                                  handleDeleteClick(content._id)
                                                }
                                              >
                                                <i className="fa fa-trash"></i>
                                              </a>
                                            </div>
                                          </td>
                                        </>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-12 d-flex justify-content-center gap-3">
                        <h2>No Users Added</h2>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
export default DisplayAllUserTableData;
