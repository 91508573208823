import React, { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Spinner,
} from "react-bootstrap";

/// imge

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";

import Swal from "sweetalert2";
import { deleteFormJoinApi } from "../../../store/DeleteFromJointSlicer";
import { getFormJoinApi } from "../../../store/GetFormJoinSlicer";

const JoinUsTable = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.formJoin);
  const [formData, setFormData] = useState([]);
  const handleClickDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are going to delete this Message",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteFormJoinApi(id)).then((resulet) => {
          Swal.fire("Deleted!", "User has been deleted", "success");
          getAllData();
        });
      }
    });

    console.log(id);
  };

  const getAllData = () => {
    dispatch(getFormJoinApi()).then((resulet) => {
      if (resulet.payload.message === "Data sent successfully") {
        setFormData(resulet.payload.data);
      } else {
        setFormData([]);
      }
    });
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <Fragment>
      <PageTitle activeMenu="Join Us" motherMenu="Forms" />

      {loading ? (
        <>
          <div className="row d-flex justify-content-center gap-2">
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
          </div>
        </>
      ) : (
        <>
          {formData.length == 0 ? (
            <>
              <div className="row d-flex text-center gap-2">
                <h3>There Is No Messages</h3>
              </div>
            </>
          ) : (
            <>
              <Row>
                <Col lg={12}>
                  <Card>
                    <Card.Body>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              <strong>Action</strong>
                            </th>
                            <th>
                              <strong>Date</strong>
                            </th>

                            <th>
                              <strong>NAME</strong>
                            </th>
                            <th>
                              <strong>EMAIL</strong>
                            </th>
                            <th>
                              <strong>PHONE</strong>
                            </th>
                            <th>
                              <strong>EDUCATION</strong>
                            </th>
                            <th>
                              <strong>DESCRIPTION</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {formData.map((item, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td>
                                    <div>
                                      <button
                                        onClick={() =>
                                          handleClickDelete(item._id)
                                        }
                                        className="btn btn-danger shadow btn-xs sharp"
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </div>
                                  </td>
                                  <td>{item.createdAt}</td>
                                  <td> {item.name}</td>
                                  <td> {item.email} </td>
                                  <td> {item.phone}</td>
                                  <td> {item.subject}</td>
                                  <td> {item.message}</td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </Fragment>
  );
};

export default JoinUsTable;
