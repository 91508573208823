import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import { Formik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import { Alert } from "react-bootstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addUserFunction } from "../../../../store/AddUserSlicer";
import { useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddNewTeacher = () => {
  const notifyWarning = (msg) => {
    toast.warn(`❗ ${msg} !`, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const { loading } = useSelector((state) => state.addUsers);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  // User Token
  const [userTok, setUserTok] = useState("");
  useEffect(() => {
    const userToken = JSON.parse(localStorage.getItem("userData"));
    setUserTok(userToken.token);
  }, []);

  // form controle
  const [fullName, setFullName] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");

  const [imgWallpaper, setImgWallpaper] = useState("");
  const [imgWallpaperFile, setImgWallpaperFile] = useState("");
  const fileUploaderImgWallpaper = useRef(null);
  const openFileUploaderImgWallpaper = () => {
    fileUploaderImgWallpaper.current.click();
  };
  //form errors
  const [fullNameErrorMessage, setFullNameErrorMessage] = useState("");
  const [titleErrorMessage, setTitleErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState("");
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");

  const checkErrorMessages = () => {
   
      if (
        fullNameErrorMessage === "" &&
        titleErrorMessage === "" &&
        emailErrorMessage === "" &&
        passwordErrorMessage === "" &&
        confirmPasswordErrorMessage === "" &&
        phoneErrorMessage === ""
      ) {
        return true;
      }
      return false;
    
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!checkErrorMessages()) {
      swal("Worrning!", "Pleace Enter Right Data", "error");
    } else {
      var formData;
        formData = {
          name: fullName,
          title: title,
          mobile: phone,
          email: email,
          password: password,
          image: imgWallpaperFile,
          role: role,
        };
     
      dispatch(addUserFunction(formData)).then((result) => {
        if (result.payload.message === " User created Successfully") {
          swal("Good job!", result.payload.message, "success");
          setFullName("");
          setTitle("");
          setEmail("");
          setPassword("");
          setConfirmPassword("");
          setPhone("");
          setRole("");
          setImgWallpaper("");
          setImgWallpaperFile("");
        } else {
          
          swal("Error", result.payload.message, "error");
        }
      });
    }
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <div className="form-validation">
              <form className="form-valide" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="form-group mb-3 row">
                      <label
                        className="col-lg-4 col-form-label"
                        htmlFor="val-skill"
                      >
                        Role
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-6">
                        <select
                          required
                          className="form-control"
                          id="val-skill"
                          name="val-skill"
                          onChange={(e) => {
                            setRole(e.target.value);
                          }}
                          value={role}
                        >
                          <option value="">Please select</option>
                          <option value="student">student</option>
                          <option value="teacher">teacher</option>
                          <option value="admin">admin</option>
                        </select>
                      </div>
                    </div>

                    {role != "" && (
                      <>
                        <div className="form-group mb-3 row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Full Name
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              required
                              type="text"
                              className="form-control"
                              id="val-username"
                              name="val-username"
                              placeholder="Enter a full name.."
                              onChange={(e) => {
                                if (
                                  e.target.value.length < 3 ||
                                  e.target.value.length > 50
                                ) {
                                  setFullNameErrorMessage(
                                    "Min 3 Characters Max 50 Characters"
                                  );
                                  setFullName(e.target.value);
                                } else {
                                  setFullNameErrorMessage("");
                                  setFullName(e.target.value);
                                }
                              }}
                              value={fullName}
                            />
                            {fullNameErrorMessage && (
                              <Alert
                                variant="warning"
                                className="alert-dismissible fade mt-1 mb-0"
                              >
                                <svg
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="me-2"
                                >
                                  <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                  <line x1="15" y1="9" x2="9" y2="15"></line>
                                  <line x1="9" y1="9" x2="15" y2="15"></line>
                                </svg>
                                <strong>Warrning </strong>
                                {fullNameErrorMessage}
                              </Alert>
                            )}
                          </div>
                        </div>

                        <div className="form-group mb-3 row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-title"
                          >
                            User Title
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              required
                              type="text"
                              className="form-control"
                              id="val-title"
                              name="val-title"
                              placeholder="Enter a user title"
                              onChange={(e) => {
                                if (
                                  e.target.value.length < 3 ||
                                  e.target.value.length > 50
                                ) {
                                  setTitleErrorMessage(
                                    "Min 3 Characters Max 50 Characters"
                                  );
                                  setTitle(e.target.value);
                                } else {
                                  setTitleErrorMessage("");
                                  setTitle(e.target.value);
                                }
                              }}
                              value={title}
                            />
                            {titleErrorMessage && (
                              <Alert
                                variant="warning"
                                className="alert-dismissible fade mt-1 mb-0"
                              >
                                <svg
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="me-2"
                                >
                                  <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                  <line x1="15" y1="9" x2="9" y2="15"></line>
                                  <line x1="9" y1="9" x2="15" y2="15"></line>
                                </svg>
                                <strong>Warrning </strong>
                                {titleErrorMessage}
                              </Alert>
                            )}
                          </div>
                        </div>

                        <div className="form-group mb-3 row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-email"
                          >
                            Email <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              required
                              type="text"
                              className="form-control"
                              id="val-email"
                              name="val-email"
                              placeholder="Your valid email.."
                              onChange={(e) => {
                                if (e.target.value.length < 10) {
                                  setEmailErrorMessage(
                                    "invaild email min 10 characters"
                                  );
                                  setEmail(e.target.value);
                                } else {
                                  setEmailErrorMessage("");
                                  setEmail(e.target.value);
                                }
                              }}
                              value={email}
                            />
                            {emailErrorMessage && (
                              <Alert
                                variant="warning"
                                className="alert-dismissible fade mt-1 mb-0"
                              >
                                <svg
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="me-2"
                                >
                                  <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                  <line x1="15" y1="9" x2="9" y2="15"></line>
                                  <line x1="9" y1="9" x2="15" y2="15"></line>
                                </svg>
                                <strong>Warrning </strong>
                                {emailErrorMessage}
                              </Alert>
                            )}
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-email"
                          >
                            Upload Image <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <div className="custom-upload-file form-group mb-3">
                              <label
                                htmlFor="img-wallpaper"
                                className="form-label"
                                onClick={() => openFileUploaderImgWallpaper}
                              >
                                <i className="fa-solid fa-upload p-2"></i>
                                {imgWallpaper
                                  ? imgWallpaper
                                  : "Upload Image Wallpaper  700 X 450"}
                              </label>
                              <input
                                ref={fileUploaderImgWallpaper}
                                hidden
                                className="form-control form-control-md"
                                type="file"
                                id="img-wallpaper"
                                onChange={(e) => {
                                  setImgWallpaperFile(e.target.files[0]);
                                  setImgWallpaper("");
                                  setImgWallpaper(e.target.files[0].name);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-password"
                          >
                            Password
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <div className="input-group transparent-append mb-2">
                              <span className="input-group-text">
                                <i className="fa fa-lock" />
                              </span>

                              <input
                                required
                                type={`${showPassword ? "text" : "password"}`}
                                className="form-control"
                                id="val-password"
                                name="password"
                                placeholder="Choose a safe one.."
                                onChange={(e) => {
                                  if (e.target.value.length < 8) {
                                    setPasswordErrorMessage(
                                      "Password less then 8 characters"
                                    );
                                    setPassword(e.target.value);
                                  } else {
                                    setPasswordErrorMessage("");
                                    setPassword(e.target.value);
                                  }
                                }}
                                value={password}
                              />

                              <div
                                className="input-group-text "
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {" "}
                                {showPassword === false ? (
                                  <i className="fa fa-eye-slash" />
                                ) : (
                                  <i className="fa fa-eye" />
                                )}
                              </div>
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              ></div>
                            </div>
                            {passwordErrorMessage && (
                              <Alert
                                variant="warning"
                                className="alert-dismissible fade mt-1 mb-0"
                              >
                                <svg
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="me-2"
                                >
                                  <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                  <line x1="15" y1="9" x2="9" y2="15"></line>
                                  <line x1="9" y1="9" x2="15" y2="15"></line>
                                </svg>
                                <strong>Warrning </strong>
                                {passwordErrorMessage}
                              </Alert>
                            )}
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-confirm-password"
                          >
                            Confirm Password
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <div className="input-group transparent-append mb-2">
                              <span className="input-group-text">
                                <i className="fa fa-lock" />
                              </span>

                              <input
                                required
                                type={`${showPassword ? "text" : "password"}`}
                                className="form-control"
                                id="val-confirm-password"
                                name="val-confirm-password"
                                placeholder="Choose a safe one.."
                                onChange={(e) => {
                                  setConfirmPassword(e.target.value);
                                  if (e.target.value != password) {
                                    setConfirmPasswordErrorMessage(
                                      "Password not the same "
                                    );
                                  } else {
                                    setConfirmPasswordErrorMessage("");
                                  }
                                }}
                                value={confirmPassword}
                              />

                              <div
                                className="input-group-text "
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {" "}
                                {showPassword === false ? (
                                  <i className="fa fa-eye-slash" />
                                ) : (
                                  <i className="fa fa-eye" />
                                )}
                              </div>
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {/* {errors.password && errors.password} */}
                              </div>
                            </div>
                            {confirmPasswordErrorMessage && (
                              <Alert
                                variant="warning"
                                className="alert-dismissible fade mt-1 mb-0"
                              >
                                <svg
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="me-2"
                                >
                                  <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                  <line x1="15" y1="9" x2="9" y2="15"></line>
                                  <line x1="9" y1="9" x2="15" y2="15"></line>
                                </svg>
                                <strong>Warrning </strong>
                                {confirmPasswordErrorMessage}
                              </Alert>
                            )}
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-phoneus"
                          >
                            Phone (US)
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              maxLength={11}
                              type="text"
                              className="form-control"
                              id="val-phoneus"
                              name="val-phoneus"
                              placeholder="212-999-0000"
                              onChange={(e) => {
                                if (e.target.value[0] !== "0") {
                                  setPhoneErrorMessage("Should Start With 0");
                                } else if (e.target.value[1] !== "1") {
                                  setPhoneErrorMessage("Should Start With 01");
                                } else if (e.target.value.length < 11) {
                                  setPhoneErrorMessage(
                                    "Not Valid Egyption Number 01xxxxxxxxx"
                                  );
                                } else {
                                  setPhoneErrorMessage("");
                                }
                                setPhone(e.target.value);
                              }}
                              value={phone}
                            />
                            {phoneErrorMessage && (
                              <Alert
                                variant="warning"
                                className="alert-dismissible fade mt-1 mb-0"
                              >
                                <svg
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="me-2"
                                >
                                  <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                  <line x1="15" y1="9" x2="9" y2="15"></line>
                                  <line x1="9" y1="9" x2="15" y2="15"></line>
                                </svg>
                                <strong>Warrning </strong>
                                {phoneErrorMessage}
                              </Alert>
                            )}
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <div className="col-lg-8 ms-auto">
                            {loading ? (
                              <>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled
                                >
                                  Loading.....
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Submit
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewTeacher;

/**
 * 
 * 
 * import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import { Formik } from "formik";
import * as Yup from "yup";

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, "Your username must consist of at least 3 characters ")
    .max(50, "Your username must consist of at least 3 characters ")
    .required("Please enter a username"),
  password: Yup.string()
    .min(5, "Your password must be at least 5 characters long")
    .max(50, "Your password must be at least 5 characters long")
    .required("Please provide a password"),
});

const JQueryValidation = () => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Fragment>
      <PageTitle
        activeMenu="Validation"
        motherMenu="Form"
        pageContent="Validation"
      />

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Form Validation</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          Username
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="val-username"
                            name="val-username"
                            placeholder="Enter a username.."
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-email"
                        >
                          Email <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="val-email"
                            name="val-email"
                            placeholder="Your valid email.."
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-password"
                        >
                          Password
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="password"
                            className="form-control"
                            id="val-password"
                            name="val-password"
                            placeholder="Choose a safe one.."
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-confirm-password"
                        >
                          Confirm Password{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="password"
                            className="form-control"
                            id="val-confirm-password"
                            name="val-confirm-password"
                            placeholder="..and confirm it!"
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-suggestions"
                        >
                          Suggestions <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <textarea
                            className="form-control"
                            id="val-suggestions"
                            name="val-suggestions"
                            rows="5"
                            placeholder="What would you like to see?"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-skill"
                        >
                          Best Skill
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <select
                            className="form-control"
                            id="val-skill"
                            name="val-skill"
                          >
                            <option value="">Please select</option>
                            <option value="html">HTML</option>
                            <option value="css">CSS</option>
                            <option value="javascript">JavaScript</option>
                            <option value="angular">Angular</option>
                            <option value="angular">React</option>
                            <option value="vuejs">Vue.js</option>
                            <option value="ruby">Ruby</option>
                            <option value="php">PHP</option>
                            <option value="asp">ASP.NET</option>
                            <option value="python">Python</option>
                            <option value="mysql">MySQL</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-currency"
                        >
                          Currency
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="val-currency"
                            name="val-currency"
                            placeholder="$21.60"
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-website"
                        >
                          Website
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="val-website"
                            name="val-website"
                            placeholder="http://example.com"
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-phoneus"
                        >
                          Phone (US)
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="val-phoneus"
                            name="val-phoneus"
                            placeholder="212-999-0000"
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-digits"
                        >
                          Digits <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="val-digits"
                            name="val-digits"
                            placeholder="5"
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-number"
                        >
                          Number <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="val-number"
                            name="val-number"
                            placeholder="5.0"
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-range"
                        >
                          Range [1, 5]
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            id="val-range"
                            name="val-range"
                            placeholder="4"
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <label className="col-lg-4 col-form-label">
                          <Link to="form-validation-jquery">
                            Terms &amp; Conditions
                          </Link>{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-8">
                          <label
                            className="form-check css-control-primary css-checkbox"
                            htmlFor="val-terms"
                          >
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              id="val-terms"
                              name="val-terms"
                              value="1"
                            />
                            <span className="css-control-indicator"></span> I
                            agree to the terms
                          </label>
                        </div>
                      </div>
                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 ms-auto">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Vertical Forms with icon</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <Formik
                  initialValues={{ username: "", password: "" }}
                  validationSchema={loginSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      alert(JSON.stringify(values, null, 2));
                      setSubmitting(false);
                    }, 400);
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div
                        className={`form-group mb-3 ${
                          values.username
                            ? errors.username
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">Username</label>
                        <div className="input-group">
                            <span className="input-group-text">
                              <i className="fa fa-user" />{" "}
                            </span>
                          <input
                            type="text"
                            className="form-control"
                            id="val-username1"
                            placeholder="Enter a username.."
                            name="username"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                          />
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.username && errors.username}
                          </div>

                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          />
                        </div>
                      </div>
                      <div
                        className={`form-group mb-3 ${
                          values.password
                            ? errors.password
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <label className="text-label">Password *</label>
                        <div className="input-group transparent-append mb-2">
                          
                            <span className="input-group-text">
                              {" "}
                              <i className="fa fa-lock" />{" "}
                            </span>
                          
                          <input
                            type={`${showPassword ? "text" : "password"}`}
                            className="form-control"
                            id="val-password1"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            placeholder="Choose a safe one.."
                          />

                          <div
                            className="input-group-text "
                            onClick={() => setShowPassword(!showPassword)}
                          >

                              {" "}
								  {showPassword === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}
                            
                          </div>
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.password && errors.password}
                          </div>
                        </div>
                      </div>

                      <div className="form-group mb-3">
                        <div className="form-check">
                          <input
                            id="checkbox1"
                            className="form-check-input"
                            type="checkbox"
                          />
                          <label
                            htmlFor="checkbox1"
                            className="form-check-label"
                          >
                            Check me out
                          </label>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn me-2 btn-primary"
                        disabled={isSubmitting}
                      >
                        Submit
                      </button>
                      <button type="submit" className="btn btn-light">
                        cencel
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default JQueryValidation;

 */
