import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import { Tab, Nav, Accordion, Spinner, Dropdown } from "react-bootstrap";
import "react-modal-video/css/modal-video.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as Yup from "yup";
import { AboutTabContent } from "./CourseDetail1";
import DropDownBlog from "./../Dashboard/DropDownBlog";
//Images
import videoimg from "./../../../images/courses/video-img.jpg";

import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { FetchCoursesByID } from "../../../store/SingleCourseSlicer";
import swal from "sweetalert";
import { set } from "date-fns/esm";
import { addLessnApi, tdrate } from "../../../store/AddessonSlicer";
import { getLessonsApi } from "../../../store/GetAllLessonSlicer";
import { title } from "process";
import { deleteLessonsApi } from "../../../store/DeleteLessonSlicer";
import Swal from "sweetalert2";

//import StudentApexLineChart from './Instructor/StudentApexLineChart';
//import UserApexLineChart from './Instructor/UserApexLineChart';
//import StudentsActivityApex from './Instructor/StudentsActivityApex';

import pic3 from "./../../../images/courses/pic3.jpg";
import pic2 from "./../../../images/courses/pic2.jpg";
import pic4 from "./../../../images/courses/pic4.jpg";
import pMinDelay from "p-min-delay";
import loadable from "@loadable/component";
import ReactPlayer from "react-player";
import { updateCourseApi } from "../../../store/UpdateCourseDataSlicer";
import axios from "axios";
import { getLessonByID } from "../../../store/GetSingleLessonDataSlicer";
import { deleteLessonFileApi } from "../../../store/Slices/lessons/DeleteLessonFileSlicer";

const updateCoureSchema = Yup.object().shape({
  enname: Yup.string()
    .min(6, "course name must consist of at least 6 characters ")
    .max(200, "course name must consist of at most 200 characters ")
    .required("Please enter a course name"),
  arname: Yup.string()
    .min(6, "course name must consist of at least 6 characters ")
    .max(200, "course name must consist of at most 200 characters ")
    .required("Please enter a course name"),
  endescription: Yup.string()
    .min(10, "description  must consist of at least 10 characters ")
    .max(3000, "description must consist of at most 3000 characters ")
    .required("Please enter a course description"),
  ardescription: Yup.string()
    .min(10, "description  must consist of at least 10 characters ")
    .max(3000, "description must consist of at most 3000 characters ")
    .required("Please enter a course description"),
  price: Yup.number().required("Please enter a price"),
});
const lessonsSchemaValidation = Yup.object().shape({
  name: Yup.string()
    .min(6, "Your name must consist of at least 6 characters ")
    .max(50, "Your name must consist of at most 50 characters ")
    .required("Please enter a lesson name"),

  section: Yup.number().required("Please Select Section"),
  duration: Yup.number().required("Please Duration"),
});
const fileUploadSchema = Yup.object().shape({
  file: Yup.mixed().required("Please upload a file pdf or word"),
});

const LessonCard = ({ type, lessonTitile, count }) => {
  return (
    <>
      <div className="acc-courses my-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="acc-icon">
              {type === "lesson" ? (
                <>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 13C3.817 13 3.635 12.95 3.474 12.851C3.32918 12.7611 3.20965 12.6358 3.12671 12.4869C3.04378 12.338 3.00016 12.1704 3 12V4C3 3.653 3.18 3.331 3.474 3.149C3.61914 3.05976 3.7846 3.00891 3.95481 3.00121C4.12502 2.99351 4.29439 3.02923 4.447 3.105L12.447 7.105C12.6131 7.1882 12.7528 7.31599 12.8504 7.47405C12.948 7.63212 12.9997 7.81423 12.9997 8C12.9997 8.18578 12.948 8.36789 12.8504 8.52595C12.7528 8.68402 12.6131 8.8118 12.447 8.895L4.447 12.895C4.307 12.965 4.152 13 4 13Z"
                      fill="var(--primary)"
                    />
                  </svg>
                </>
              ) : (
                <>
                  <svg
                    fill="#000000"
                    width="16px"
                    height="16px"
                    viewBox="0 0 32 32"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M16 0c-8.836 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16.001-7.163 16.001-16s-7.163-16-16.001-16zM16 30.032c-7.72 0-14-6.312-14-14.032s6.28-14 14-14 14.001 6.28 14.001 14-6.281 14.032-14.001 14.032zM14.53 25.015h2.516v-2.539h-2.516zM15.97 6.985c-1.465 0-2.672 0.395-3.62 1.184s-1.409 2.37-1.386 3.68l0.037 0.073h2.295c0-0.781 0.261-1.904 0.781-2.308s1.152-0.604 1.893-0.604c0.854 0 1.511 0.232 1.971 0.696s0.689 1.127 0.689 1.989c0 0.725-0.17 1.343-0.512 1.855-0.343 0.512-0.916 1.245-1.721 2.198-0.831 0.749-1.344 1.351-1.538 1.806s-0.297 1.274-0.305 2.454h2.405c0-0.74 0.047-1.285 0.14-1.636s0.36-0.744 0.799-1.184c0.945-0.911 1.703-1.802 2.277-2.674 0.573-0.87 0.86-1.831 0.86-2.881 0-1.465-0.443-2.607-1.331-3.424s-2.134-1.226-3.736-1.226z"></path>
                  </svg>
                </>
              )}
            </span>
            <h4 className="m-0">{lessonTitile}</h4>
          </div>
          <span>
            {count} {type === "lesson" ? "min" : "quetions"}
          </span>
        </div>
      </div>
    </>
  );
};
const AccordionItemLesson = ({
  data,
  deleteLessonFromApi,
  displayLessonVideo,
  updateLessonFrom,
  uploadFileForm,
  deleteLessonFile,
}) => {
  console.log(data);

  return (
    <>
      <div className="acc-courses my-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span
              className="acc-icon"
              onClick={() => displayLessonVideo(data.video)}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 13C3.817 13 3.635 12.95 3.474 12.851C3.32918 12.7611 3.20965 12.6358 3.12671 12.4869C3.04378 12.338 3.00016 12.1704 3 12V4C3 3.653 3.18 3.331 3.474 3.149C3.61914 3.05976 3.7846 3.00891 3.95481 3.00121C4.12502 2.99351 4.29439 3.02923 4.447 3.105L12.447 7.105C12.6131 7.1882 12.7528 7.31599 12.8504 7.47405C12.948 7.63212 12.9997 7.81423 12.9997 8C12.9997 8.18578 12.948 8.36789 12.8504 8.52595C12.7528 8.68402 12.6131 8.8118 12.447 8.895L4.447 12.895C4.307 12.965 4.152 13 4 13Z"
                  fill="var(--primary)"
                />
              </svg>
            </span>
            <h4 className="m-0">
              {data.name}

              {data.duration && (
                <>
                  <span className=" mx-3 badge bg-success">
                    {data.duration} min
                  </span>
                </>
              )}
            </h4>
          </div>

          <span>
            <Dropdown>
              <Dropdown.Toggle
                as="a"
                className="btn-link i-false btn sharp tp-btn-light btn-dark"
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0012 9.86C11.6544 9.86 11.3109 9.92832 10.9905 10.061C10.67 10.1938 10.3789 10.3883 10.1336 10.6336C9.88835 10.8788 9.6938 11.17 9.56107 11.4905C9.42834 11.8109 9.36002 12.1544 9.36002 12.5012C9.36002 12.848 9.42834 13.1915 9.56107 13.5119C9.6938 13.8324 9.88835 14.1236 10.1336 14.3688C10.3789 14.6141 10.67 14.8086 10.9905 14.9413C11.3109 15.0741 11.6544 15.1424 12.0012 15.1424C12.7017 15.1422 13.3734 14.8638 13.8687 14.3684C14.3639 13.873 14.642 13.2011 14.6418 12.5006C14.6417 11.8001 14.3632 11.1284 13.8678 10.6332C13.3724 10.138 12.7005 9.85984 12 9.86H12.0012ZM3.60122 9.86C3.25437 9.86 2.91092 9.92832 2.59048 10.061C2.27003 10.1938 1.97887 10.3883 1.73361 10.6336C1.48835 10.8788 1.2938 11.17 1.16107 11.4905C1.02834 11.8109 0.960022 12.1544 0.960022 12.5012C0.960022 12.848 1.02834 13.1915 1.16107 13.5119C1.2938 13.8324 1.48835 14.1236 1.73361 14.3688C1.97887 14.6141 2.27003 14.8086 2.59048 14.9413C2.91092 15.0741 3.25437 15.1424 3.60122 15.1424C4.30171 15.1422 4.97345 14.8638 5.46866 14.3684C5.96387 13.873 6.24198 13.2011 6.24182 12.5006C6.24166 11.8001 5.96324 11.1284 5.46781 10.6332C4.97237 10.138 4.30051 9.85984 3.60002 9.86H3.60122ZM20.4012 9.86C20.0544 9.86 19.7109 9.92832 19.3905 10.061C19.07 10.1938 18.7789 10.3883 18.5336 10.6336C18.2884 10.8788 18.0938 11.17 17.9611 11.4905C17.8283 11.8109 17.76 12.1544 17.76 12.5012C17.76 12.848 17.8283 13.1915 17.9611 13.5119C18.0938 13.8324 18.2884 14.1236 18.5336 14.3688C18.7789 14.6141 19.07 14.8086 19.3905 14.9413C19.7109 15.0741 20.0544 15.1424 20.4012 15.1424C21.1017 15.1422 21.7734 14.8638 22.2687 14.3684C22.7639 13.873 23.042 13.2011 23.0418 12.5006C23.0417 11.8001 22.7632 11.1284 22.2678 10.6332C21.7724 10.138 21.1005 9.85984 20.4 9.86H20.4012Z"
                    fill="#A098AE"
                  />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="dropdown-menu dropdown-menu-end"
                align="right"
              >
                {data.file ? (
                  <>
                    {data.file.path !== "" && (
                      <>
                        <Dropdown.Item
                          onClick={() => deleteLessonFile(data._id)}
                        >
                          Remove File
                        </Dropdown.Item>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Dropdown.Item onClick={() => uploadFileForm(data._id)}>
                      upload file
                    </Dropdown.Item>
                  </>
                )}
                <Dropdown.Item onClick={() => updateLessonFrom(data, data._id)}>
                  Edit
                </Dropdown.Item>

                <Dropdown.Item onClick={() => deleteLessonFromApi(data._id)}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </div>
        {data.file && (
          <>
            <div>
              <h5>
                <a
                  className="p-1 text-danger"
                  href={`${process.env.REACT_APP_VERSEL_API}/${data.file.path}`}
                  target="_blank"
                >
                  file name :{data.file.name}
                </a>
              </h5>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const CustomDropdown = ({ handleShowCourseEditModule }) => {
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          as="a"
          className="btn-link i-false btn sharp tp-btn-light btn-dark"
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0012 9.86C11.6544 9.86 11.3109 9.92832 10.9905 10.061C10.67 10.1938 10.3789 10.3883 10.1336 10.6336C9.88835 10.8788 9.6938 11.17 9.56107 11.4905C9.42834 11.8109 9.36002 12.1544 9.36002 12.5012C9.36002 12.848 9.42834 13.1915 9.56107 13.5119C9.6938 13.8324 9.88835 14.1236 10.1336 14.3688C10.3789 14.6141 10.67 14.8086 10.9905 14.9413C11.3109 15.0741 11.6544 15.1424 12.0012 15.1424C12.7017 15.1422 13.3734 14.8638 13.8687 14.3684C14.3639 13.873 14.642 13.2011 14.6418 12.5006C14.6417 11.8001 14.3632 11.1284 13.8678 10.6332C13.3724 10.138 12.7005 9.85984 12 9.86H12.0012ZM3.60122 9.86C3.25437 9.86 2.91092 9.92832 2.59048 10.061C2.27003 10.1938 1.97887 10.3883 1.73361 10.6336C1.48835 10.8788 1.2938 11.17 1.16107 11.4905C1.02834 11.8109 0.960022 12.1544 0.960022 12.5012C0.960022 12.848 1.02834 13.1915 1.16107 13.5119C1.2938 13.8324 1.48835 14.1236 1.73361 14.3688C1.97887 14.6141 2.27003 14.8086 2.59048 14.9413C2.91092 15.0741 3.25437 15.1424 3.60122 15.1424C4.30171 15.1422 4.97345 14.8638 5.46866 14.3684C5.96387 13.873 6.24198 13.2011 6.24182 12.5006C6.24166 11.8001 5.96324 11.1284 5.46781 10.6332C4.97237 10.138 4.30051 9.85984 3.60002 9.86H3.60122ZM20.4012 9.86C20.0544 9.86 19.7109 9.92832 19.3905 10.061C19.07 10.1938 18.7789 10.3883 18.5336 10.6336C18.2884 10.8788 18.0938 11.17 17.9611 11.4905C17.8283 11.8109 17.76 12.1544 17.76 12.5012C17.76 12.848 17.8283 13.1915 17.9611 13.5119C18.0938 13.8324 18.2884 14.1236 18.5336 14.3688C18.7789 14.6141 19.07 14.8086 19.3905 14.9413C19.7109 15.0741 20.0544 15.1424 20.4012 15.1424C21.1017 15.1422 21.7734 14.8638 22.2687 14.3684C22.7639 13.873 23.042 13.2011 23.0418 12.5006C23.0417 11.8001 22.7632 11.1284 22.2678 10.6332C21.7724 10.138 21.1005 9.85984 20.4 9.86H20.4012Z"
              fill="#A098AE"
            />
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="dropdown-menu dropdown-menu-end"
          align="right"
        >
          <Dropdown.Item
            onClick={() => {
              handleShowCourseEditModule();
            }}
          >
            Edit
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

const CourseDetail2 = () => {
  /// Update Lesson Area

  const { _id } = useParams();
  const [courseData, setCourseData] = useState([]);

  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.singleCourseData);
  const { loading: allLessonsLoading, respons } = useSelector(
    (state) => state.getLessons
  );
  const { loading: updatecourseLoading } = useSelector(
    (state) => state.updateCourse
  );

  const [sectionData, setSectionData] = useState([]);

  // File Refrances Lesson Video

  // Data States Lesson Infromation
  const [lessonTitle, setLessonTitle] = useState("");
  const [lessonDuration, setLessonDuration] = useState(0);
  const [lessonSection, setLessonSection] = useState();
  const [lessonVideoTitle, setLessonVideoTitle] = useState("");
  const [lessonVideoTitleFile, setLessonVideoFile] = useState("");

  // Data States Quiz Infromation
  const [quizTite, setQuizTite] = useState("");
  const [quizQuestionNumbers, setquizQuestionNumbers] = useState(0);
  const [quizSection, setQuizSection] = useState(0);
  const [quizId, setLQuizID] = useState("");

  // File Open Functions

  ///////////////////////////////

  // Add Quiz Model
  const [showQuizForm, setShowQuizForm] = useState(false);
  const handleCloseQuizForm = () => setShowQuizForm(false);
  const handleShowQuizForm = () => setShowQuizForm(true);

  //#region

  const [courseFormIntilization, setCourseFormInitialization] = useState({
    enname: "",
    arname: "",
    endescription: "",
    ardescription: "",
    image: "",
    imageName: "",
    price: 0,
    teacherId: "",
    courseId: "",
  });

  // handle Update Data

  const fileUploaderImgCourse = useRef(null);



  const openFileUploaderImgCourse = () => {
    fileUploaderImgCourse.current.click();
  };

  // Show Update Model
  const [courseEditModule, setCourseEditModule] = useState(false);
  const handleCloseCourseEditModule = () => {
    setCourseEditModule(false);
    setCourseFormInitialization({
      enname: "",
      arname: "",
      endescription: "",
      ardescription: "",
      image: "",
      imageName: "",
      price: 0,
      teacherId: "",
      courseId: "",
    })
  };
  const handleShowCourseEditModule = (id) => {
    setCourseFormInitialization({
      enname:courseData.name.en,
      arname: courseData.name.ar,
      endescription: courseData.description.en,
      ardescription: courseData.description.en,
      image: courseData.image,
      imageName: courseData.imageName,
      price: courseData.price / 100,
      teacherId: courseData.teacher_id._id,
      courseId: _id,
    });
    setCourseEditModule(true);

  };

  const handleUpdateCourseData = (value ,{resetForm}) => {
    console.log(value);

    let formData = new FormData();

    formData.append('name[en]', value.enname);
    formData.append('name[ar]',value.arname);
    formData.append('description[en]', value.endescription);
    formData.append('description[ar]',value.ardescription);

    formData.append('price',value.price*100);

    formData.append("teacherId", value.teacherId);
    formData.append("courseId",value.courseId);

    if(value.image){
    formData.append('image',value.image);

    }

    dispatch(updateCourseApi(formData)).then((res) => {
      if (res.payload.message == "Done") {
        Swal.fire("Updated!", "Course Data Has Been Updated", "success");
        handleCloseCourseEditModule();
        resetForm();
        getCoures();
      } else {
        Swal.fire("Error!", res.payload.message, "warning");
      }
    });
  };

  //#endregion

  //#region
  /*----------------------------- */
  // create Lesson Area
  // Add Lesson Model

  const createLessonFileRefreance = useRef(null);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [createLesson, setCreateLesson] = useState({});
  const [showLessonForm, setShowLessonForm] = useState(false);

  const handleCloseLessonForm = () => {
    setShowLessonForm(false);
    setCreateLesson({
      name: "",
      section: 0,
      duration: 1,
      video: "",
      videsName: "",
    });
  };
  const handleShowLessonForm = () => {
    setShowLessonForm(true);
    setCreateLesson({
      name: "",
      section: 0,
      duration: 1,
      video: "",
      videsName: "",
    });
  };
  const openCreateLessonFile = () => {
    createLessonFileRefreance.current.click();
  };

  //Form handler
  const handleLessonFormUlpoader = async (value, { resetForm }) => {
    setIsUploading(true);
    setProgress(0);

    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;

    let formData = new FormData();
    formData.append("courseId", _id);

    formData.append("name", value.name);
    formData.append("section", value.section);
    formData.append("duration", value.duration);
    formData.append("video", value.video);

    const request = await axios
      .request({
        timeout: 0,
        method: "post",
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
        url: `${process.env.REACT_APP_VERSEL_API}/api/users/lessons/create`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${tokenData}`,
        },
        data: formData,
        onUploadProgress: (e) => {
          const uploadedRateData = Math.floor(
            Math.round(100 * e.loaded) / e.total
          );
          setProgress(uploadedRateData);
          console.log(uploadedRateData);
        },
      })
      .then((result) => {
        setIsUploading(false);
        setProgress(0);

        if (result.data.message === "lesson added successfully") {
          swal("Lesson", "Lesson Add Successfuly ", "success");
          resetForm();
          getAllLessonsFromAPI();
          handleCloseLessonForm();
        } else {
          swal("Error", result.data.message, "error");
          setIsUploading(false);
          setProgress(0);
        }
      })
      .catch((error) => {
        console.log(error);
        swal("Oops", `${error}`, "error");
      });
  };

  // Update Lesson Area

  const [progressUpdate, setProgressUpdate] = useState(0);
  const [isUploadingUpdate, setIsUploadingUpdate] = useState(false);

  const [showUpdateLesson, setShowUpdateLesson] = useState(false);

  const [formIntilaData, setFormIntilaData] = useState({
    lessonId: "",
    name: "",
    section: 0,
    duration: 1,
    video: "",
    videsName: "",
  });

  const fileUploaderLessonVideo = useRef(null);

  const openFileUploaderLessonVideos = () => {
    fileUploaderLessonVideo.current.click();
  };

  const handleShowUpdateLessonMedule = (data, lessonId) => {
    setFormIntilaData({
      lessonId: lessonId,
      name: data.name,
      duration: data.duration,
      section: data.section,
    });

    setShowUpdateLesson(true);
  };

  const handleCloseUpdateLessonMedule = () => {
    setFormIntilaData({
      lessonId: "",
      name: "",
      section: 0,
      duration: 1,
      video: "",
      videsName: "",
    });
    setShowUpdateLesson(false);
  };

  const handleFormSubmite = async (value, { resetForm }) => {
    setIsUploadingUpdate(true);
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;
    let projectFormData = new FormData();
    projectFormData.append("name", value.name);
    projectFormData.append("section", value.section);
    projectFormData.append("duration", value.duration);

    if (value.video !== "") {
      projectFormData.append("video", value.video);
    }
    await axios
      .request({
        timeout: 0,
        method: "put",
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
        url: `${process.env.REACT_APP_VERSEL_API}/api/users/lessons/update/${value.lessonId}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${tokenData}`,
        },
        data: projectFormData,

        onUploadProgress: (e) => {
          const uploadedRateData = Math.floor(
            Math.round(100 * e.loaded) / e.total
          );
          setProgressUpdate(uploadedRateData);
        },
      })
      .then((result) => {
        setIsUploadingUpdate(false);
        if (result.data.message === "Done") {
          swal("Done!", result.data.message, "success");
          resetForm();
          setProgressUpdate(0);
          setIsUploadingUpdate(false);
          getAllLessonsFromAPI();
          setShowUpdateLesson(false);
        } else {
          swal("Error", result.data.message, "error");
          setProgressUpdate(0);
          setIsUploadingUpdate(false);
        }
      })
      .catch((error) => {
        swal("Oops", `${error}`, "error");
        setProgressUpdate(0);
        setIsUploadingUpdate(false);
      });
  };

  // Show Videos Model
  const [videoUrl, setVideoUrl] = useState(null);
  const [showVideoLesson, setShowVideoLesson] = useState(false);

  // handle cloase module
  const handleCloseVideoLesson = () => {
    setVideoUrl(null);
    setShowVideoLesson(false);
  };
  // handle get All Lessons
  const getAllLessonsFromAPI = () => {
    dispatch(getLessonsApi(_id)).then((resulet) => {
      setSectionData(resulet.payload.data);
    });
  };

  // handle Delete Lesson
  const deleteLessonFromApi = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are going to delete this lesson",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteLessonsApi(id)).then((res) => {
          if (res.payload.message === "Lesson Deleted successfully") {
            Swal.fire("Deleted!", res.payload.message, "success");
            getAllLessonsFromAPI();
          } else {
            Swal.fire("Deleted!", res.payload.message, "error");
          }
        });
      }
    });
  };

  // handle Show Lesson
  const displayLessonVideo = (lessonId) => {
    dispatch(getLessonByID(lessonId)).then((res) => {
      if (res.payload.data) {
        setVideoUrl(
          `${process.env.REACT_APP_VERSEL_API}/${res.payload.data.video}`
        );
        setShowVideoLesson(true);
      }
    });
  };

  //#endregion

  //#region

  /*----------------------------- */
  /// upload file area

  const [fileLessonIsUploading, setFileLessonIsUploading] = useState(false);
  const [fileLessonProgressBar, setFileLessonProgressBar] = useState(0);

  const updateNewfileUploaderLessonVideo = useRef(null);
  const openUpdateFileUploaderLessonVideos = () => {
    updateNewfileUploaderLessonVideo.current.click();
  };

  const fileLessonUploaderRefreance = useRef(null);
  const openFileLessonUploader = () => {
    fileLessonUploaderRefreance.current.click();
  };

  const [fileUploaderInitialData, setFileUploaderInitialData] = useState({
    lessonId: "",
    type: "",
    filename: "",
    file: "",
  });
  const [showFileLessonUploadModule, setShowFileLessonUploadModule] =
    useState(false);

  const handleCloceFileLessonUploadModule = () => {
    setShowFileLessonUploadModule(false);
    setFileLessonIsUploading(false);
    setFileLessonProgressBar(0);
    setFileUploaderInitialData({
      lessonId: "",
      filename: "",
      file: "",
    });
  };

  const uploadFileForm = (lessonId) => {
    console.log(lessonId);
    setFileUploaderInitialData({
      lessonId: lessonId,
      filename: "",
      file: "",
    });
    setShowFileLessonUploadModule(true);
  };

  const handelSubmitFileUploaderForm = async (value, { resetForm }) => {
    console.log(value);
    setFileLessonIsUploading(true);
    setFileLessonProgressBar(0);

    // let options = {
    //   url: "",
    //   method: "",
    // };
    // if (value.type === "create") {
    //   options.url = `${process.env.REACT_APP_VERSEL_API}/api/lessons/file/uploadeFile/${value.lessonId}`;
    //   options.method = "POST";
    // } else {
    //   options.url = `${process.env.REACT_APP_VERSEL_API}/api/lessons/file/updateUploadeFile/${value.lessonId}`;
    //   options.method = "PUT";
    // }

    const token = JSON.parse(localStorage.getItem("userData")).token;
    const formData = new FormData();
    formData.append("file", value.file);
    await axios
      .request({
        method: "POST",
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
        url: `${process.env.REACT_APP_VERSEL_API}/api/lessons/file/uploadeFile/${value.lessonId}`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        data: formData,
        onUploadProgress: (e) => {
          const uploadedRatedData = Math.floor(
            Math.round(100 * e.loaded) / e.total
          );
          setFileLessonProgressBar(uploadedRatedData);
        },
      })
      .then((res) => {
        if (res.data.message === "File added successfully") {
          setFileLessonIsUploading(false);
          swal("File", "File Add Successfuly ", "success");
          resetForm();
          getAllLessonsFromAPI();
          handleCloceFileLessonUploadModule();
        } else if (res.data.message === "File updated successfully") {
          swal("File", "File updated Successfuly ", "success");
          resetForm();
          getAllLessonsFromAPI();
          handleCloceFileLessonUploadModule();
        } else {
          swal("File", res.data.message, "info");
        }
      })
      .catch((err) => {
        setFileLessonIsUploading(false);
        setFileLessonProgressBar(0);
        swal("Oops", `${err}`, "error");
      });
  };

  const deleteLessonFile = (lesId) => {
    dispatch(deleteLessonFileApi(lesId)).then((res) => {
      if (res.payload.message === "File deleted successfully") {
        swal("File", "File Deleted Successfuly ", "success");
        getAllLessonsFromAPI();
      } else {
        swal("File", res.payload.message, "error");
      }
    });
  };

  //#endregion

  const getCoures = () => {
    dispatch(FetchCoursesByID(_id)).then((resulet) => {
      if (resulet.payload.message === "fetched successfully") {
        setCourseData(resulet.payload.data.course);
        console.log(courseData);
      }
    });
  };

  useEffect(() => {
    getCoures();
    getAllLessonsFromAPI();
  }, []);
  // useEffect(() => {

  //   getAllLessonsFromAPI();
  // }, []);

  useEffect(() => {
    if (!courseData) {
      getCoures();
    }
    return;
  }, [courseData]);

  return (
    <>
      {loading ? (
        <>
          <div className="row d-flex justify-content-center gap-2">
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
          </div>
        </>
      ) : (
        <>
          {error ? (
            <>{error}</>
          ) : (
            <>
              {courseData && (
                <>
                  <div className="row">
                    <div className="col-xl-6 col-xxl-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="course-content ">
                            <div className="mb-2">
                              <CustomDropdown
                                handleShowCourseEditModule={
                                  handleShowCourseEditModule
                                }
                              />
                            </div>
                            <div>
                              <h3>{courseData.name && courseData.name.en}</h3>
                              <ul className="d-flex align-items-center raiting my-0 flex-wrap">
                                {courseData.teacher_id != null && (
                                  <li>
                                    Intractor : {courseData.teacher_id.name}
                                  </li>
                                )}
                                <li>
                                  Price : {courseData.price / 100} 
                                  $
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="video-img style-1">
                            <div className="view-demo">
                              <img
                                src={`${process.env.REACT_APP_VERSEL_API}/${courseData.image}`}
                                alt={courseData.name && courseData.name.en}
                              />
                            </div>
                          </div>
                          <Tab.Container defaultActiveKey="About">
                            <div className="course-details-tab style-2 mt-4">
                              <nav>
                                <Nav
                                  as="div"
                                  className="nav nav-tabs tab-auto"
                                  id="nav-tab"
                                >
                                  <Nav.Link
                                    as="button"
                                    className="nav-link"
                                    id="nav-about-tab"
                                    eventKey="About"
                                    type="button"
                                  >
                                    English Data
                                  </Nav.Link>
                                  <Nav.Link
                                    as="button"
                                    className="nav-link"
                                    id="nav-aboutar-tab"
                                    eventKey="Aboutar"
                                    type="button"
                                  >
                                    Arabic Data
                                  </Nav.Link>
                                </Nav>
                              </nav>
                              <Tab.Content
                                className="tab-content"
                                id="nav-tabContent"
                                style={{
                                  textAlign: "justify",
                                }}
                              >
                                <Tab.Pane id="nav-about" eventKey="About">
                                  <div className="about-content">
                                    <h4>Course Name</h4>
                                    <p>
                                      {courseData.name && courseData.name.en}
                                    </p>
                                    <h4>About This Course</h4>
                                    <p>
                                      {courseData.description &&
                                        courseData.description.en}
                                    </p>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane
                                  id="nav-about"
                                  eventKey="Aboutar"
                                  dir="rtl"
                                >
                                  <div className="about-content">
                                    <h4>اسم الكورس</h4>
                                    <p>
                                      {courseData.name && courseData.name.ar}
                                    </p>
                                    <h4>وصف الكورس</h4>
                                    <p>
                                      {courseData.description &&
                                        courseData.description.ar}
                                    </p>
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </div>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-xxl-6">
                      <div className="card h-auto">
                        <div className="card-header border-0 pb-0">
                          <h4>ِAdded Lesson</h4>
                        </div>
                        <div className="card-body pt-0 d-flex gap-1">
                          <button
                            className="btn btn-primary"
                            onClick={handleShowLessonForm}
                          >
                            New Lesson
                          </button>
                        </div>
                      </div>
                      <div className="custome-accordion">
                        <Accordion
                          className="accordion"
                          defaultActiveKey={[`0`]}
                          alwaysOpen
                        >
                          {allLessonsLoading ? (
                            <>
                              <div className="d-flex justify-content-center align-items-center gap-1 w-100">
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                              </div>
                            </>
                          ) : (
                            <>
                              {sectionData.length == 0 ? (
                                <h3 className="text-center">
                                  No lessons Added yet
                                </h3>
                              ) : (
                                <>
                                  <Accordion.Item
                                    className="card"
                                    eventKey={`0`}
                                  >
                                    <Accordion.Header
                                      as="h2"
                                      className="accordion-header border-0"
                                    >
                                      <span className="acc-heading">
                                        Section One
                                      </span>
                                    </Accordion.Header>
                                    <Accordion.Collapse eventKey={`0`}>
                                      <div className="accordion-body card-body pt-0">
                                        {sectionData.map(
                                          (lesson, index) =>
                                            lesson.section == 0 && (
                                              <AccordionItemLesson
                                                key={`${lesson._id}-${index}`}
                                                data={lesson}
                                                uploadFileForm={uploadFileForm}
                                                deleteLessonFile={
                                                  deleteLessonFile
                                                }
                                                deleteLessonFromApi={
                                                  deleteLessonFromApi
                                                }
                                                displayLessonVideo={() =>
                                                  displayLessonVideo(lesson._id)
                                                }
                                                updateLessonFrom={
                                                  handleShowUpdateLessonMedule
                                                }
                                              />
                                            )
                                        )}
                                      </div>
                                    </Accordion.Collapse>
                                  </Accordion.Item>
                                  <Accordion.Item
                                    className="card"
                                    eventKey={`1`}
                                  >
                                    <Accordion.Header
                                      as="h2"
                                      className="accordion-header border-0"
                                    >
                                      <span className="acc-heading">
                                        Section Two
                                      </span>
                                    </Accordion.Header>
                                    <Accordion.Collapse eventKey={`1`}>
                                      <div className="accordion-body card-body pt-0">
                                        {sectionData.map(
                                          (lesson, index) =>
                                            lesson.section == 1 && (
                                              <AccordionItemLesson
                                                key={`${lesson._id}-${index}`}
                                                data={lesson}
                                                uploadFileForm={uploadFileForm}
                                                deleteLessonFile={
                                                  deleteLessonFile
                                                }
                                                deleteLessonFromApi={
                                                  deleteLessonFromApi
                                                }
                                                displayLessonVideo={() =>
                                                  displayLessonVideo(lesson._id)
                                                }
                                                updateLessonFrom={
                                                  handleShowUpdateLessonMedule
                                                }
                                              />
                                            )
                                        )}
                                      </div>
                                    </Accordion.Collapse>
                                  </Accordion.Item>
                                  <Accordion.Item
                                    className="card"
                                    eventKey={`2`}
                                  >
                                    <Accordion.Header
                                      as="h2"
                                      className="accordion-header border-0"
                                    >
                                      <span className="acc-heading">
                                        Section Three
                                      </span>
                                    </Accordion.Header>
                                    <Accordion.Collapse eventKey={`2`}>
                                      <div className="accordion-body card-body pt-0">
                                        {sectionData.map(
                                          (lesson, index) =>
                                            lesson.section == 2 && (
                                              <AccordionItemLesson
                                                key={`${lesson._id}-${index}`}
                                                data={lesson}
                                                uploadFileForm={uploadFileForm}
                                                deleteLessonFile={
                                                  deleteLessonFile
                                                }
                                                deleteLessonFromApi={
                                                  deleteLessonFromApi
                                                }
                                                displayLessonVideo={() =>
                                                  displayLessonVideo(lesson._id)
                                                }
                                                updateLessonFrom={
                                                  handleShowUpdateLessonMedule
                                                }
                                              />
                                            )
                                        )}
                                      </div>
                                    </Accordion.Collapse>
                                  </Accordion.Item>
                                  <Accordion.Item
                                    className="card"
                                    eventKey={`3`}
                                  >
                                    <Accordion.Header
                                      as="h2"
                                      className="accordion-header border-0"
                                    >
                                      <span className="acc-heading">
                                        Section Four
                                      </span>
                                    </Accordion.Header>
                                    <Accordion.Collapse eventKey={`3`}>
                                      <div className="accordion-body card-body pt-0">
                                        {sectionData.map(
                                          (lesson, index) =>
                                            lesson.section == 3 && (
                                              <AccordionItemLesson
                                                key={`${lesson._id}-${index}`}
                                                data={lesson}
                                                uploadFileForm={uploadFileForm}
                                                deleteLessonFile={
                                                  deleteLessonFile
                                                }
                                                deleteLessonFromApi={
                                                  deleteLessonFromApi
                                                }
                                                displayLessonVideo={() =>
                                                  displayLessonVideo(lesson._id)
                                                }
                                                updateLessonFrom={
                                                  handleShowUpdateLessonMedule
                                                }
                                              />
                                            )
                                        )}
                                      </div>
                                    </Accordion.Collapse>
                                  </Accordion.Item>
                                  <Accordion.Item
                                    className="card"
                                    eventKey={`4`}
                                  >
                                    <Accordion.Header
                                      as="h2"
                                      className="accordion-header border-0"
                                    >
                                      <span className="acc-heading">
                                        Section Five
                                      </span>
                                    </Accordion.Header>
                                    <Accordion.Collapse eventKey={`4`}>
                                      <div className="accordion-body card-body pt-0">
                                        {sectionData.map(
                                          (lesson, index) =>
                                            lesson.section == 4 && (
                                              <AccordionItemLesson
                                                key={`${lesson._id}-${index}`}
                                                data={lesson}
                                                uploadFileForm={uploadFileForm}
                                                deleteLessonFile={
                                                  deleteLessonFile
                                                }
                                                deleteLessonFromApi={
                                                  deleteLessonFromApi
                                                }
                                                displayLessonVideo={() =>
                                                  displayLessonVideo(lesson._id)
                                                }
                                                updateLessonFrom={
                                                  handleShowUpdateLessonMedule
                                                }
                                              />
                                            )
                                        )}
                                      </div>
                                    </Accordion.Collapse>
                                  </Accordion.Item>
                                </>
                              )}
                            </>
                          )}
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          {/* Add Lesson Form */}
          <Modal
            show={showLessonForm}
            onHide={handleCloseLessonForm}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Body>
              <form className="row" onSubmit={handleLessonFormUlpoader}>
                <div className="col-lg-12 mb-2">
                  <div className="form-group mb-3">
                    <label className="text-label">Lesson Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Lesson one : Introduction to something ....."
                      required
                      onChange={(e) => {
                        setLessonTitle(e.target.value);
                      }}
                      value={lessonTitle}
                    />
                  </div>
                </div>
                <div className="col-lg-12 mb-3">
                  <div className="row">
                    <div className="col-sm-6">
                      <label className="text-label">
                        Duration*(number of mineut)
                      </label>
                      <input
                        required
                        maxLength={2}
                        type="number"
                        className="form-control"
                        placeholder="minute"
                        onChange={(e) => {
                          setLessonDuration(e.target.value);
                        }}
                        value={lessonDuration}
                      />
                    </div>
                    <div className="col-sm-6 mt-2 mt-sm-0">
                      <label className="text-label">Lesson Section</label>
                      <select
                        required
                        className="form-control"
                        onChange={(e) => {
                          setLessonSection(e.target.value);
                        }}
                        value={lessonSection}
                      >
                        <option defaultValue={null}>Select Section</option>
                        <option value={0}>Section 1</option>
                        <option value={1}>Section 2</option>
                        <option value={2}>Section 3</option>
                        <option value={3}>Section 4</option>
                        <option value={4}>Section 5</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="custom-upload-file form-group mb-3">
                    <label
                      htmlFor="lesson-video"
                      className="form-label"
                      onClick={() => openFileUploaderLessonVideos}
                    >
                      <i className="fa-solid fa-upload p-2"></i>
                      {lessonVideoTitle
                        ? lessonVideoTitle
                        : "Upload Lesson Video"}
                    </label>
                    <input
                      required
                      ref={fileUploaderLessonVideo}
                      hidden
                      className="form-control form-control-md"
                      type="file"
                      id="lesson-video"
                      onChange={(e) => {
                        setLessonVideoFile(e.target.files[0]);
                        setLessonVideoTitle("");
                        setLessonVideoTitle(e.target.files[0].name);
                      }}
                    />
                  </div>
                </div>
                {isUploading && (
                  <>
                    <h5>uploading video now please waite...</h5>
                    <div className="progress-box">
                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="mb-0 fs-14 font-w600">
                          Uploading video new.., please don't close window
                        </h5>
                        <span className="font-w600" id="progress-span">
                          {progress}%
                        </span>
                      </div>
                      <div className="progress ">
                        <div
                          className="progress-bar bg-primary"
                          id="progress-inner-width"
                          style={{
                            width: `${progress}%`,
                            height: "12px",
                            borderRadius: "4px",
                          }}
                          role="progressbar"
                        ></div>
                      </div>
                    </div>
                  </>
                )}

                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseLessonForm}>
                    Close
                  </Button>
                  {isUploading ? (
                    <>
                      <Button variant="primary" disabled>
                        Uplaoding.....
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button variant="primary" type="submit">
                        Create Lesson
                      </Button>
                    </>
                  )}
                </Modal.Footer>
              </form>
            </Modal.Body>
          </Modal>

          {/* Display Videso Lesson */}
          <Modal
            show={showVideoLesson}
            onHide={handleCloseVideoLesson}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <h3>play Video</h3>
            </Modal.Header>
            <Modal.Body>
              {videoUrl ? (
                <>
                  <ReactPlayer
                    className="m-auto"
                    url={videoUrl}
                    controls
                    config={{
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                  />
                </>
              ) : (
                <>
                  <h2>No Video Url</h2>
                </>
              )}
            </Modal.Body>
          </Modal>
         
          {/* Update Course Data Formik  */}
          <Modal
            show={courseEditModule}
            onHide={handleCloseCourseEditModule}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Body>
              <Formik
                initialValues={{
                  enname: courseFormIntilization.enname,
                  arname: courseFormIntilization.arname,

                  endescription: courseFormIntilization.endescription,
                  ardescription: courseFormIntilization.ardescription,

                  image: courseFormIntilization.image,
                  imageName: courseFormIntilization.imageName,

                  price: courseFormIntilization.price,
                  teacherId: courseFormIntilization.teacherId,
                  courseId: courseFormIntilization.courseId,
                }}
                validationSchema={updateCoureSchema}
                onSubmit={handleUpdateCourseData}
              >
                {({
                  values,
                  errors,
                  setValues,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form className="row" onSubmit={handleSubmit}>
                    <div>
                      <h1>Basic Infromation</h1>
                      <section>
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.enname
                                ? errors.enname
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter English Coures Name
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter a english name.."
                                name="enname"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.enname}
                              />
                              <div
                                id="val-name-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.enname && errors.enname}
                              </div>
                            </div>
                          </div>
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.arname
                                ? errors.arname
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter Arabic Coures Name
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter a arabic name.."
                                name="arname"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.arname}
                              />
                              <div
                                id="val-name-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.arname && errors.arname}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.price
                                ? errors.price
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter New Price* (Egyption pound)
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter course price .."
                                name="price"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.price}
                              />
                              <div
                                id="val-price-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.price && errors.price}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.image
                                ? errors.image
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <p className="text-danger">
                              * Uploade New Image, don't upload image if you
                              don't waon't update image
                            </p>
                            <label
                              htmlFor="img-course"
                              className="form-label"
                              onClick={openFileUploaderImgCourse}
                              style={{
                                cursor: "pointer",
                                width: "100%",
                                padding: "2rem",
                                backgroundColor: "#1f88d9",
                                opacity: "0.5",
                                border: "3px dashed blue",
                                borderRadius: "10px",
                                color: "#111",
                                fontSize: "1rem",
                                textAlign: "center",
                                overflow: "hidden",
                              }}
                            >
                              {values.imageName
                                ? values.imageName
                                : "Upload New Image Course 700 X 450 typr of jpg ,png ,jpeg"}
                            </label>
                            <div className="input-group">
                              <input
                                ref={fileUploaderImgCourse}
                                hidden
                                className="form-control form-control-md"
                                type="file"
                                name="file"
                                onChange={(e) => {
                                  setValues({
                                    ...values,
                                    imageName: e.target.files[0].name,
                                    image: e.target.files[0],
                                  });
                                }}
                                onBlur={handleBlur}
                                value={values.file}
                              />
                              <div
                                id="val-file-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.file && errors.file}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div>
                      <h1>Descriptions </h1>
                      <section>
                        <div className="row ">
                        <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.endescription
                                ? errors.endescription
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter English Description
                            </label>
                            <div className="input-group">
                              <textarea
                                id="subdescription-textarea"
                                rows={5}
                                className="form-control"
                                placeholder="Sub Description"
                                name="endescription"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.endescription}
                              ></textarea>
                              <div
                                id="val-endescription-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.endescription && errors.endescription}
                              </div>
                            </div>
                          </div>
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.ardescription
                                ? errors.ardescription
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter Arabic Description
                            </label>
                            <div className="input-group">
                              <textarea
                                id="subdescription-textarea"
                                rows={5}
                                className="form-control"
                                placeholder="Sub Description"
                                name="ardescription"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ardescription}
                              ></textarea>
                              <div
                                id="val-ardescription-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.ardescription && errors.ardescription}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>

                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleCloseCourseEditModule}
                      >
                        Close
                      </Button>
                      {updatecourseLoading ? (
                        <>
                          <Button variant="primary" disabled>
                            Updateting ...
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button variant="primary" type="submit">
                            Update Course
                          </Button>
                        </>
                      )}
                    </Modal.Footer>
                  </form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>

          {/* Create Lesson Form */}
          <Modal
            show={showLessonForm}
            onHide={handleCloseLessonForm}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>Add Lesson</Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{
                  name: createLesson.name,
                  section: createLesson.section,
                  duration: createLesson.duration,
                  video: createLesson.video,
                }}
                validationSchema={lessonsSchemaValidation}
                onSubmit={handleLessonFormUlpoader}
              >
                {({
                  values,
                  errors,
                  setValues,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <>
                      <div className="row">
                        <div
                          className={` col-lg-12 col-sm-12 form-group mb-3 ${
                            values.name
                              ? errors.name
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                        >
                          <label className="text-label">
                            Enter Lesson Name
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="fa-solid fa-file-signature"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter a english name.."
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                            />
                            <div
                              id="val-name-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.name && errors.name}
                            </div>
                          </div>
                        </div>

                        <div
                          className={`col-12 form-group mb-3 ${
                            values.section
                              ? errors.section
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                        >
                          <label className="text-label">Select Section </label>
                          <div className="input-group transparent-append mb-2">
                            <select
                              className="form-control"
                              name="section"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.section}
                              placeholder="Chose Project Service"
                            >
                              <option defaultValue={""}>Please select</option>

                              <option value={0}>section 1</option>
                              <option value={1}>section 2</option>
                              <option value={2}>section 3</option>
                              <option value={3}>section 4</option>
                              <option value={4}>section 5</option>
                            </select>
                            <div
                              id="val-section-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.section && errors.section}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`col-12 form-group mb-3 ${
                            values.duration
                              ? errors.duration
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                        >
                          <label className="text-label">Enter Duration </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="fa-solid fa-file-signature"></i>
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter a video duration.."
                              name="duration"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.duration}
                            />
                            <div
                              id="val-duration-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.duration && errors.duration}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Image Inputs Data */}
                      <div className="row">
                        <div
                          className="card-header"
                          style={{
                            border: "transparent",
                          }}
                        >
                          <h4 className="card-title">Uploade Video</h4>
                        </div>
                        {/* Video  */}
                        <div className={`col-lg-12 col-sm-12 form-group mb-3`}>
                          <label
                            onClick={openCreateLessonFile}
                            className="text-label"
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              padding: "2rem",
                              backgroundColor: "#1f88d9",
                              opacity: "0.5",
                              border: "3px dashed blue",
                              borderRadius: "10px",
                              color: "#111",
                              fontSize: "1rem",
                              textAlign: "center",
                              overflow: "hidden",
                            }}
                          >
                            {values.videsName
                              ? values.videsName
                              : "  Upload New Video  type of mp4 , mov , wmv , m4v"}
                          </label>
                          <div className="input-group">
                            <input
                              accept="video/*"
                              type="file"
                              className="form-control"
                              name="video"
                              hidden
                              ref={createLessonFileRefreance}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  videsName: e.target.files[0].name,
                                  video: e.target.files[0],
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      {isUploading && (
                        <>
                          <h5>uploading video now please waite...</h5>
                          <div className="progress-box">
                            <div className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0 fs-14 font-w600">
                                Uploading video new.., please don't close window
                              </h5>
                              <span className="font-w600" id="progress-span">
                                {progress}%
                              </span>
                            </div>
                            <div className="progress ">
                              <div
                                className="progress-bar bg-primary"
                                id="progress-inner-width"
                                style={{
                                  width: `${progress}%`,
                                  height: "12px",
                                  borderRadius: "4px",
                                }}
                                role="progressbar"
                              ></div>
                            </div>
                          </div>
                        </>
                      )}

                      {isUploading ? (
                        <>
                          <button className="btn me-2 btn-primary" disabled>
                            <Spinner animation="grow" variant="warning" />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn me-2 btn-primary"
                          >
                            Submit
                          </button>
                        </>
                      )}
                      <button
                        type="button"
                        onClick={handleCloseLessonForm}
                        className="btn me-2 btn-danger"
                      >
                        Close
                      </button>
                    </>
                  </form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>

          {/* Update Lesson Form */}
          <Modal
            show={showUpdateLesson}
            onHide={handleCloseUpdateLessonMedule}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>Update Lesson</Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{
                  lessonId: formIntilaData.lessonId,
                  name: formIntilaData.name,
                  section: formIntilaData.section,
                  duration: formIntilaData.duration,
                  video: formIntilaData.video,
                }}
                validationSchema={lessonsSchemaValidation}
                onSubmit={handleFormSubmite}
              >
                {({
                  values,
                  errors,
                  setValues,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <>
                      <div className="row">
                        <div
                          className={` col-lg-12 col-sm-12 form-group mb-3 ${
                            values.name
                              ? errors.name
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                        >
                          <label className="text-label">
                            Enter Lesson Name
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="fa-solid fa-file-signature"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter a english name.."
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                            />
                            <div
                              id="val-name-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.name && errors.name}
                            </div>
                          </div>
                        </div>

                        <div
                          className={`col-12 form-group mb-3 ${
                            values.section
                              ? errors.section
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                        >
                          <label className="text-label">Select Section </label>
                          <div className="input-group transparent-append mb-2">
                            <select
                              className="form-control"
                              name="section"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.section}
                              placeholder="Chose Project Service"
                            >
                              <option defaultValue={""}>Please select</option>

                              <option value={0}>section 1</option>
                              <option value={1}>section 2</option>
                              <option value={2}>section 3</option>
                              <option value={3}>section 4</option>
                              <option value={4}>section 5</option>
                            </select>
                            <div
                              id="val-section-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.section && errors.section}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`col-12 form-group mb-3 ${
                            values.duration
                              ? errors.duration
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                        >
                          <label className="text-label">Enter Duration </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="fa-solid fa-file-signature"></i>
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter a video duration.."
                              name="duration"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.duration}
                            />
                            <div
                              id="val-duration-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.duration && errors.duration}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Image Inputs Data */}
                      <div className="row">
                        <div
                          className="card-header"
                          style={{
                            border: "transparent",
                          }}
                        >
                          <h4 className="card-title">Uploade Video</h4>
                        </div>
                        {/* Video  */}
                        <div className={`col-lg-12 col-sm-12 form-group mb-3`}>
                          <label
                            onClick={openUpdateFileUploaderLessonVideos}
                            className="text-label"
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              padding: "2rem",
                              backgroundColor: "#1f88d9",
                              opacity: "0.5",
                              border: "3px dashed blue",
                              borderRadius: "10px",
                              color: "#111",
                              fontSize: "1rem",
                              textAlign: "center",
                              overflow: "hidden",
                            }}
                          >
                            {values.videsName
                              ? values.videsName
                              : "  Upload New Video  type of mp4 , mov , wmv , m4v "}
                          </label>
                          <div className="input-group">
                            <input
                              accept="video/*"
                              type="file"
                              className="form-control"
                              name="video"
                              hidden
                              ref={updateNewfileUploaderLessonVideo}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  videsName: e.target.files[0].name,
                                  video: e.target.files[0],
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {isUploadingUpdate && (
                        <>
                          <h5>uploading video now please waite...</h5>
                          <div className="progress-box">
                            <div className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0 fs-14 font-w600">
                                Uploading video new.., please don't close window
                              </h5>
                              <span className="font-w600" id="progress-span">
                                {progressUpdate}%
                              </span>
                            </div>
                            <div className="progress ">
                              <div
                                className="progress-bar bg-primary"
                                id="progress-inner-width"
                                style={{
                                  width: `${progressUpdate}%`,
                                  height: "12px",
                                  borderRadius: "4px",
                                }}
                                role="progressbar"
                              ></div>
                            </div>
                          </div>
                        </>
                      )}
                      {isUploadingUpdate ? (
                        <>
                          <button className="btn me-2 btn-primary" disabled>
                            <Spinner animation="grow" variant="warning" />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn me-2 btn-primary"
                          >
                            Submit
                          </button>
                        </>
                      )}
                      <button
                        type="button"
                        onClick={handleCloseUpdateLessonMedule}
                        className="btn me-2 btn-danger"
                      >
                        Close
                      </button>
                    </>
                  </form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>

          {/* Upload File Form */}
          <Modal
            show={showFileLessonUploadModule}
            onHide={handleCloceFileLessonUploadModule}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Body>
              <Formik
                initialValues={{
                  lessonId: fileUploaderInitialData.lessonId,
                  filename: fileUploaderInitialData.filename,
                  file: fileUploaderInitialData.file,
                }}
                validationSchema={fileUploadSchema}
                onSubmit={handelSubmitFileUploaderForm}
              >
                {({
                  values,
                  errors,
                  setValues,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <>
                      <div className="row">
                        <div
                          className="card-header"
                          style={{
                            border: "transparent",
                          }}
                        >
                          <h4 className="card-title">Upload Lesson File</h4>
                        </div>
                        {/* Video  */}
                        <div
                          className={` col-lg-12 col-sm-12 form-group mb-3 ${
                            values.file
                              ? errors.file
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                        >
                          <label
                            onClick={openFileLessonUploader}
                            className="text-label"
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              padding: "2rem",
                              backgroundColor: "#1f88d9",
                              opacity: "0.5",
                              border: "3px dashed blue",
                              borderRadius: "10px",
                              color: "#111",
                              fontSize: "1rem",
                              textAlign: "center",
                              overflow: "hidden",
                            }}
                          >
                            {values.filename
                              ? values.filename
                              : "  Upload New File type of word , pdf "}
                          </label>
                          <div className="input-group">
                            <input
                              accept="application/*"
                              type="file"
                              className="form-control"
                              name="file"
                              hidden
                              ref={fileLessonUploaderRefreance}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  filename: e.target.files[0].name,
                                  file: e.target.files[0],
                                });
                              }}
                            />
                          </div>
                          <div
                            id="val-name-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.file && errors.file}
                          </div>
                        </div>
                      </div>
                      {fileLessonIsUploading && (
                        <>
                          <div className="progress-box">
                            <div className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0 fs-14 font-w600">
                                Uploading file now.., please don't close window
                              </h5>
                              <span className="font-w600" id="progress-span">
                                {fileLessonProgressBar}%
                              </span>
                            </div>
                            <div className="progress ">
                              <div
                                className="progress-bar bg-primary"
                                id="progress-inner-width"
                                style={{
                                  width: `${fileLessonProgressBar}%`,
                                  height: "12px",
                                  borderRadius: "4px",
                                }}
                                role="progressbar"
                              ></div>
                            </div>
                          </div>
                        </>
                      )}
                      {fileLessonIsUploading ? (
                        <>
                          <button className="btn me-2 btn-primary" disabled>
                            <Spinner animation="grow" variant="warning" />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn me-2 btn-primary"
                          >
                            upload
                          </button>
                        </>
                      )}
                      <button
                        type="button"
                        onClick={handleCloceFileLessonUploadModule}
                        className="btn me-2 btn-danger"
                      >
                        Close
                      </button>
                    </>
                  </form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};
export default CourseDetail2;
