import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import DropDownBlog from "./DropDownBlog";
//images
import avatarMain from "./../../../images/avatar/avatar-main.png";
import acheiv from "./../../../images/svg/achievement.svg";
import medal from "./../../../images/medal.png";
import pic3 from "./../../../images/courses/pic3.jpg";
import pic4 from "./../../../images/courses/pic4.jpg";
import pic5 from "./../../../images/courses/pic5.jpg";
import pic6 from "./../../../images/courses/pic6.jpg";
import pic7 from "./../../../images/courses/pic7.jpg";
import pic8 from "./../../../images/courses/pic8.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getAll } from "../../../store/GetUsersSlicer";
import { Spinner } from "react-bootstrap";
import { deleteUser } from "../../../store/DeleteStudentSlicer";
import Swal from "sweetalert2";

const instructorsData = [
  { title: "Samantha William", image: pic8 },
  { title: "Nadila Adja", image: pic4 },
  { title: "Johnny Ahmad", image: pic7 },
  { title: "Angelina Crispy", image: pic5 },
  { title: "Tony Soap", image: pic3 },
  { title: "Jordan Nico", image: pic6 },
];

const Instructors = () => {
  const { loading, data, error } = useSelector((state) => state.getUser);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    console.log(id);
    Swal.fire({
      title: "Are you sure?",
      text: "You are going to delete this Student",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteUser(id)).then((res) => {
          if (res.payload.message === "deleted successfully") {
            Swal.fire("Deleted!", "User has been deleted", "success");
          } else {
            Swal.fire(
              "Error!",
              "Something bad happened ,try again later!!",
              "info"
            );
          }
          dispatch(getAll("teacher")).then((resulet) => {
            if (resulet.payload.message === "fetched successfully") {
              setUsers(resulet.payload.data);
            }
          });
        });
      }
    });
  };

  useEffect(() => {
    dispatch(getAll("teacher")).then((resulet) => {
      if (resulet.payload.message === "fetched successfully") {
        console.log(resulet.payload.data);
        setUsers(resulet.payload.data);
      }
    });
    // dispatch(GetUsersSlicer('teacher')).
  }, []);

  return (
    <>
      <div className="row">
        {loading ? (
          <>
            <div className="row d-flex justify-content-center gap-2">
              <Spinner animation="grow" variant="warning" />
              <Spinner animation="grow" variant="warning" />
              <Spinner animation="grow" variant="warning" />
              <Spinner animation="grow" variant="warning" />
            </div>
          </>
        ) : (
          <>
            {users.length == 0 ? (
              <div className="row d-flex text-center gap-2">
                <h3>No Data Added Yet</h3>
              </div>
            ) : (
              <>
                {users &&
                  users.map((item, index) =>
                     (
                      <>
                        <div
                          className="col-xl-4 col-xxl-6 col-md-6"
                          key={index}
                        >
                          <div className="card instructors-box">
                            {/* <div className="card-header border-0">
                <DropDownBlog />
              </div> */}
                            <div className="card-body text-center pb-3">
                              <div className="instructors-media">
                                {item.image ? (
                                  <img
                                    src={`${process.env.REACT_APP_VERSEL_API}/${item.image}`}
                                    alt={item.name}
                                  />
                                ) : (
                                  <img src={avatarMain} alt={item.name} />
                                )}

                                <div className="instructors-media-info">
                                  <h4>{item.name}</h4>
                                  {item.title && (
                                    <p>{item.title}</p> 
                                  ) }

                                  {/* <div className="custome-badge">
											<Link to={"#"}><span className="badge badge-xl">Design</span></Link>
											<Link to={"#"}><span className="badge badge-xl">Tech</span></Link>
											<Link to={"#"}><span className="badge badge-xl">Research</span></Link>
										</div> */}
                                  <div className="d-flex justify-content-center my-3">
                                    {/* <div className="info-box">
                        <span>
                          <img src={medal} alt="" /> Courses
                        </span>
                        <h4>100</h4>
                      </div> */}
                                    <div className="info-box">
                                      <span>
                                        <img src={acheiv} alt="" /> Courses
                                      </span>
                                      <h4>{item.numberOfCourses}</h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card-footer d-flex justify-content-between gap-2 pt-0 border-0">
                              <Link
                                to={`./add-courese/${item._id}`}
                                className="btn btn-primary  btn-block"
                              >
                                Add Course
                              </Link>
                              <Link
                                to={`./app-profile/${item._id}`}
                                className="btn btn-secondary  btn-block"
                              >
                                View Profile
                              </Link>
                              <button
                                onClick={() => handleDelete(item._id)}
                                className="btn btn-danger  btn-block"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  )}
              </>
            )}
          </>
        )}

        {/* {instructorsData.map((item, index) => (
          <div className="col-xl-4 col-xxl-6 col-md-6" key={index}>
            <div className="card instructors-box">
              <div className="card-header border-0">
                <DropDownBlog />
              </div> 
              <div className="card-body text-center pb-3">
                <div className="instructors-media">
                  <img src={item.image} alt="" />
                  <div className="instructors-media-info">
                    <h4>{item.title}</h4>
 <div className="custome-badge">
											<Link to={"#"}><span className="badge badge-xl">Design</span></Link>
											<Link to={"#"}><span className="badge badge-xl">Tech</span></Link>
											<Link to={"#"}><span className="badge badge-xl">Research</span></Link>
										</div> 
                    <div className="d-flex justify-content-center my-3">
                      <div className="info-box">
                        <span>
                          <img src={medal} alt="" /> Courses
                        </span>
                        <h4>100</h4>
                      </div>
                      <div className="info-box">
                        <span>
                          <img src={acheiv} alt="" /> Students
                        </span>
                        <h4>100</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer d-flex justify-content-between gap-2 pt-0 border-0">
                <Link
                  to={"./add-courese"}
                  className="btn btn-primary  btn-block"
                >
                  Add Course
                </Link>
                <Link
                  to={"./app-profile"}
                  className="btn btn-secondary  btn-block"
                >
                  View Profile
                </Link>
              </div>
            </div>
          </div>
        ))} */}
      </div>
    </>
  );
};
export default Instructors;
