import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";
import LogoutFunction from "../services/LogoutFunction";

export const FetchCoursesByID = createAsyncThunk(
  "course/getbyid",
   async (_id) => {
  const request = await fetch(
    `${process.env.REACT_APP_VERSEL_API}/api/students/courses/getCourseById/${_id}`,
    {
      method: "GET",
    }
  )
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => {
        console.log(error);
      formatError(error);
    });

  return request;
});

function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}

const SingleCourseSlicer = createSlice({
  name: "getCouses",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(FetchCoursesByID.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(FetchCoursesByID.fulfilled, (state, action) => {
      state.loading = false;
       if (action.payload.message === "fetched successfully") {
        state.data = action.payload.data;
      } else {
        state.error = action.payload.message;
      }
    });
    builder.addCase(FetchCoursesByID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

export default SingleCourseSlicer.reducer;

